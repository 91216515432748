import { Drawer , List , ListItemText , ListItemIcon , ListSubheader , Divider , ListItem, ListItemButton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import {
  Search,
  LocationOnOutlined,
  HelpOutlineRounded,
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./i18n/LanguageSelector.js";
import { DoorsFilter } from "./location-details/DoorsFilter.js";

type TNavigationMenuState = {
  isOpen: boolean;
  open: () => unknown;
  close: () => unknown;
};

export function NavigationMenu({
  navigationMenuState,
  tenants,
  selectedTenant,
  onTenantSelected,
}: {
  navigationMenuState: TNavigationMenuState;
  tenants: string[];
  selectedTenant: string | null;
  onTenantSelected: (tenant: string) => void;
}) {
  const { t } = useTranslation();

  const handleTanentSelected = useCallback(
    (tenant: string) => {
      onTenantSelected(tenant);
      navigationMenuState.close();
    },
    [navigationMenuState, onTenantSelected],
  );

  const tenantTiles = useMemo(() => {
    return (
      <List subheader={<ListSubheader>{t("app_menu.tenants")}</ListSubheader>}>
        {tenants.map((tenant) => (
          <TenantTile
            key={tenant}
            tenant={tenant}
            selected={tenant === selectedTenant}
            onTenantSelected={handleTanentSelected}
          ></TenantTile>
        ))}
      </List>
    );
  }, [t, tenants, selectedTenant, handleTanentSelected]);

  return (
    <Drawer
      anchor="right"
      open={navigationMenuState.isOpen}
      onClose={navigationMenuState.close}
    >
      <List>
        <ListItemButton
          component={Link}
          to="/overview"
          onClick={navigationMenuState.close}
        >
          <ListItemIcon>
            <LocationOnOutlined />
          </ListItemIcon>
          <ListItemText primary={t("common.locations")} />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`tenants/${selectedTenant ?? tenants[0]}/orders`}
          onClick={navigationMenuState.close}
        >
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText primary={t("app_menu.find_order")} />
        </ListItemButton>
      </List>

      <DoorsFilter/>
        

      <List sx={{ marginTop: "auto" }}>
        <Divider />
        {tenantTiles}
        <Divider />
        <ListItemButton
          component={Link}
          to="/imprint"
          onClick={navigationMenuState.close}
        >
          <ListItemIcon>
            <HelpOutlineRounded />
          </ListItemIcon>
          <ListItemText primary={t("app_menu.imprint")} />
        </ListItemButton>
        <ListItem>
          <LanguageSelector />
        </ListItem>
      </List>
    </Drawer>
  );
}

export function useNavigationMenuState(): TNavigationMenuState {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return useMemo(() => ({ isOpen, open, close }), [close, isOpen, open]);
}

function TenantTile({
  tenant,
  selected,
  onTenantSelected,
}: {
  tenant: string;
  selected: boolean;
  onTenantSelected: (tenant: string) => void;
}) {
  const handleSelected = useCallback(() => {
    onTenantSelected(tenant);
  }, [onTenantSelected, tenant]);

  return (
    <ListItemButton onClick={handleSelected}>
      <ListItemIcon>
        {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
      </ListItemIcon>
      <ListItemText primary={tenant} />
    </ListItemButton>
  );
}
