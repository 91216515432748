import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

import {
  getLocation,
  getLocationDoors,
  getOrder,
  getRentalsForLocation,
  getResourceTypes,
  openLocationDoors,
  TAPI,
  TGetResourceTypesResponse,
  TLocation,
  TLocationDoor,
  TOrder,
  TOrder$TikiRental,
  TOrder$TikiRental$RentalItem,
  useAPI,
} from "../api.js";
import {
  doesTokenAllowOpenLocationDoor,
  doesTokenAllowOpenLocationOrAnyDoor,
  doesTokenAllowShowOrder,
} from "../auth.js";
import { RequestErrorToast, ToastMessage } from "../ToastMessage.js";
import { TAsyncResult, useAsyncValue } from "../useAsyncValue.js";
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState, useContext } from "react";
import { TRoute } from "../types.js";
import { EmptyListText } from "../EmptyListText.js";
import { DataListItem } from "../DataListItem.js";
import { RefreshRounded } from "@mui/icons-material";
import { useNavigate } from "react-router";
//import { ReserveDoors } from "./ReserveDoors";
import { DoorCard } from "./DoorCard.js";
import { DoorList } from "./DoorList.js";
import { DateTime } from "luxon";
import { DoorsFilterContext } from "../App.js"

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const WAIT_BETWEEN_DOOR_OPEN_AND_REFETCH_MS = 3000;

export async function loadLocationDetails(
  api: TAPI,
  { locationID, tenantKey }: { locationID: string; tenantKey: string },
) {
  return getLocation(api, tenantKey, locationID);
}

export type LoadedPageData = Awaited<ReturnType<typeof loadLocationDetails>>;

export function LocationDetailsTitle({
  dataResult,
}: {
  dataResult: TAsyncResult<LoadedPageData>;
  routeParams: { locationID: string };
}) {
  return dataResult.type === "RESOLVED" ? (
    <>{dataResult.value.name}</>
  ) : (
    <>...</>
  );
}

export function LocationDetailsContent({
  dataResult: locationDataResult,
  routeParams,
}: {
  dataResult: TAsyncResult<LoadedPageData>;
  routeParams: { tenantKey: string; locationID: string };
}) {
  const { t } = useTranslation();
  const api = useAPI();

  const {statusFilter, resourceTypeFilter, resourceTypeValues} = useContext(DoorsFilterContext);
  const [dropdownFilter, setDropdownFilter] = resourceTypeFilter;
  const [checkboxFilter, setCheckboxFilter] = statusFilter;
  const [resourceTypeOptions, setResourceTypeOptions] = resourceTypeValues;

  function selectedResourceTypeDoors(){
    if(doorsResult.type === 'RESOLVED'){
      let filteredDoors:TLocationDoor[] = doorsResult.value;

      if (dropdownFilter !== null) {
        filteredDoors = doorsResult.value.filter((door) => {
          return (door.resourceTypes.includes(dropdownFilter))
        });
      }
      return filteredDoors;
    } else {
      return [];
    }
  }

  function doorsAfterFilter() {
    if (doorsResult.type === 'RESOLVED') {
      let filteredDoors:TLocationDoor[] = selectedResourceTypeDoors();
      const currentRentalCondition = (rentalInfo:any) => rentalInfo.createdAt <= DateTime.now() && rentalInfo.expiredAt > DateTime.now()
      const rentalsList = Object.keys(rentalsByDoorId).filter(key =>
        rentalsByDoorId[key]?.some(rentalInfo => currentRentalCondition(rentalInfo))
      );

      if (checkboxFilter === 'maintenance') {
        filteredDoors = selectedResourceTypeDoors().filter((door) => {
          return (door.resourceTypes.includes('MAINTENANCE'))
        })
      }

      if (checkboxFilter === 'unoccupied') {
        filteredDoors = filteredDoors.filter((door) => {
          return !rentalsList.includes(door.doorID);
        });
      }

      if (checkboxFilter === 'occupied') {
        filteredDoors = filteredDoors.filter((door) => {
          return rentalsList.includes(door.doorID);
        });
      }

      // TODO: update this to filter in backend for full proof solution.
      if (locationDataResult.type === "RESOLVED") {
        filteredDoors = filteredDoors.filter((door) => {
          return doesTokenAllowOpenLocationDoor(api.getPermissions(), locationDataResult.value.locationID, door.doorID)
        });
      }

      return Array.from(new Set(filteredDoors));
    }else {
      return [];
    }
  }

  const [resourceTypesResponse, setResourceTypes] =
    useState<TGetResourceTypesResponse | null>(null);

  const loadResourceTypes = async (
    api: TAPI,
    tenantKey: string,
    locationID: string,
  ) => {
    const data = await getResourceTypes(api, tenantKey, locationID);
    setResourceTypes(data);
  };

  useEffect(() => {
    loadResourceTypes(api, routeParams.tenantKey, routeParams.locationID);
  }, [routeParams.tenantKey, routeParams.locationID, api]);

  const [locationDoorsPromise, setLocationDoorsPromise] = useState<
    Promise<TLocationDoor[]>
  >(
    useMemo(
      () =>
        getLocationDoors(api, routeParams.tenantKey, routeParams.locationID),
      [api, routeParams.locationID, routeParams.tenantKey],
    ),
  );
  const doorsResult = useAsyncValue(locationDoorsPromise);
  const updateDoors = useCallback(() => {
    setLocationDoorsPromise(
      getLocationDoors(api, routeParams.tenantKey, routeParams.locationID),
    );
    setSelectedDoorCardIds([]);
  }, [api, routeParams.locationID, routeParams.tenantKey]);

  const [locationRentalsPromise, setLocationRentalsPromise] = useState<
    Promise<TOrder$TikiRental[]>
  >(
    useMemo(
      () =>
        getRentalsForLocation(
          api,
          routeParams.tenantKey,
          routeParams.locationID,
          DateTime.now(),
          DateTime.now().plus({ days: 1 }),
        ),
      [api, routeParams.locationID, routeParams.tenantKey],
    ),
  );
  const rentalsResult = useAsyncValue(locationRentalsPromise);
  const updateRentals = useCallback(() => {
    setLocationRentalsPromise(
      getRentalsForLocation(
        api,
        routeParams.tenantKey,
        routeParams.locationID,
        DateTime.now(),
        DateTime.now().plus({ days: 1 }),
      ),
    );
  }, [api, routeParams.locationID, routeParams.tenantKey]);

  const navigate = useNavigate();

  const hasPermission =
    locationDataResult.type === "RESOLVED"
      ? doesTokenAllowOpenLocationOrAnyDoor(
          api.getPermissions(),
          locationDataResult.value.locationID,
        )
      : null;

  useEffect(() => {
    if (locationDataResult.type === "RESOLVED" && !hasPermission) {
      navigate(`/${routeParams.tenantKey}/locations`, {
        state: {
          message: {
            text: `${t("location_details.no_permission")} ${
              locationDataResult.type === "RESOLVED"
                ? locationDataResult.value.name
                : "–"
            }`,
            severity: "warning",
          },
        },
      });
    }
  }, [
    t,
    api,
    hasPermission,
    locationDataResult,
    navigate,
    routeParams.tenantKey,
  ]);

  useEffect(() => {
    updateDoors();
    updateRentals();
  }, [routeParams.locationID, updateDoors, updateRentals]);

  const handleClickDoorOpen = useCallback((doorId: string | number) => {
    setConfirmOpenPromptDoorIds([doorId]);
    setSelectedDoorCardIds([doorId]);
  }, []);

  const [selectedDoorCardIds, setSelectedDoorCardIds] = useState<
    (string | number)[]
  >([]);
  const handleClickDoor = useCallback(
    (doorId: string | number) => {
      if (selectedDoorCardIds.includes(doorId)) {
        setSelectedDoorCardIds(
          selectedDoorCardIds.filter((id) => id !== doorId),
        );
      } else {
        setSelectedDoorCardIds([...selectedDoorCardIds, doorId]);
      }
    },
    [selectedDoorCardIds],
  );

  const isAllowedToOpenDoor = useCallback(
    (doorId: string): boolean | null =>
      locationDataResult.type === "RESOLVED"
        ? doesTokenAllowOpenLocationDoor(
            api.getPermissions(),
            locationDataResult.value.locationID,
            doorId,
          )
        : null,
    [api, locationDataResult],
  );

  const [openDoorsRequestPromise, setOpenDoorsRequestPromise] =
    useState<null | ReturnType<typeof openLocationDoors>>(null);

  const openDoorsRequestResult = useAsyncValue(openDoorsRequestPromise);

  const rentalsByDoorId = useMemo<RentalMapByDoors>(() => {
    if (rentalsResult.type !== "RESOLVED") {
      return {};
    }
    return rentalsResult.value.reduce<RentalMapByDoors>((result, rental) => {
      rental.rentalItems.forEach((rentalItem) => {
        rentalItem.resources.forEach((resource) => {
          const list = result[resource.doorID] ?? [];
          list.push({
            createdAt: Date.parse(rental.createdAt),
            expiredAt: Date.parse(rental.expiredAt),
            rental,
            rentalItem,
            resource,
          });
          result[resource.doorID] = list;
        });
      });
      return result;
    }, {} as RentalMapByDoors);
  }, [rentalsResult]);

  const isShowingOrdersAllowed = useMemo(
    () => doesTokenAllowShowOrder(api.getPermissions()),
    [api],
  );

  const [nextReservationsOrders, setNextReservationsOrders] = useState<
    Record<string, TOrder>
  >({});
  useEffect(() => {
    if (
      doorsResult.type !== "RESOLVED" ||
      !rentalsByDoorId ||
      !isShowingOrdersAllowed
    ) {
      return;
    }
    const ids: string[] = [];
    doorsResult.value.forEach((door) => {
      const nextReservation = getNextDoorReservations(
        rentalsByDoorId[door.doorID] ?? [],
      );
      const orderId = nextReservation?.rental.orderID;
      if (orderId && !orderId.includes("admin-") && !ids.includes(orderId)) {
        ids.push(orderId);
      }
    });

    let typeOptions:string[] = [];
    doorsResult.value.forEach((door) => typeOptions.push(...door.resourceTypes))

    // Removing 'MAINTENANCE' as we have checkbox filter for it separately.
    typeOptions = typeOptions.filter(doorType => doorType.trim() !== '' && doorType.trim() !== 'MAINTENANCE')
    resourceTypesResponse?.tenantResourceTypes.forEach((rt) => { typeOptions.push(rt) })
    setResourceTypeOptions(Array.from(new Set(typeOptions.sort())));


    const fetchData = async (ids: string[]) => {
      const result: Record<string, TOrder> = {};
      await Promise.all(
        ids.map(async (id) => {
          await getOrder(api, routeParams.tenantKey, id).then((order) => {
            result[id] = order;
          });
        }),
      );
      setNextReservationsOrders(result);
    };
    fetchData(ids).then(() => {});
  }, [
    api,
    doorsResult,
    isShowingOrdersAllowed,
    rentalsByDoorId,
    routeParams.tenantKey,
  ]);

  const [confirmOpenPromptDoorIds, setConfirmOpenPromptDoorIds] = useState<
    (string | number)[]
  >([]);

  const handleOpenPromptConfirmClick = useCallback(async () => {
    if (locationDataResult.type !== "RESOLVED") {
      return;
    }
    setConfirmOpenPromptDoorIds([]);

    const allowedDoors:string[] = selectedDoorCardIds.map((doorId) => doorId.toString()).filter((doorID) => {
      return doesTokenAllowOpenLocationDoor(api.getPermissions(), locationDataResult.value.locationID, doorID)
    });

    const requestPromise = openLocationDoors(
      api,
      routeParams.tenantKey,
      locationDataResult.value.locationID,
      allowedDoors,
    );
    setOpenDoorsRequestPromise(
      (async () => {
        await requestPromise;
        setTimeout(updateDoors, WAIT_BETWEEN_DOOR_OPEN_AND_REFETCH_MS);
      })(),
    );
  }, [
    api,
    locationDataResult,
    selectedDoorCardIds,
    updateDoors,
    routeParams.tenantKey,
  ]);
  const handleOpenPromptCancelClick = useCallback(() => {
    setConfirmOpenPromptDoorIds([]);
  }, []);

  const handleClickOpenSelectedDoors = useCallback(() => {
    setConfirmOpenPromptDoorIds(selectedDoorCardIds);
  }, [selectedDoorCardIds]);

  const handleConnectionStatusClick = () => {
    navigate(
      `/tenants/${routeParams.tenantKey}/location/${routeParams.locationID}/status`,
    );
  };

  const [doorsViewList, setDoorsViewList] = useState<boolean>(false);

  const handleDoorsViewType = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      setDoorsViewList(event.target.checked)
    }, []
  );

  if (locationDataResult.type === "NONE") {
    return null;
  }

  if (locationDataResult.type === "REJECTED") {
    return <RequestErrorToast requestError={locationDataResult.error} />;
  }

  if (locationDataResult.type === "PENDING") {
    return (
      <CircularProgress
        sx={{
          display: "block",
          margin: "auto",
          marginTop: 4,
          marginBottom: 4,
        }}
      />
    );
  }

  if (!hasPermission) {
    return null;
  }

  const connectionStatus =
    locationDataResult.value.connectionEvents?.[
      locationDataResult.value.connectionEvents.length - 1
    ]?.["eventType"];
  return (
    <Box sx={{ paddingTop: "24px", paddingBottom: "24px" }}>
      <Container>
        <Title variant="h4">{locationDataResult.value.name}</Title>
        <Paper>
          <List sx={{ paddingTop: 0, paddingBottom: 0 }} dense>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <DataListItem
                  value={locationDataResult.value.locationID}
                  label={t("location_details.location_id")}
                />
                <Divider />
                <DataListItem
                  value={locationDataResult.value.timezone}
                  label={t("location_details.timezone")}
                />
              </Grid>
              <Grid item xs={6}>
                <DataListItem
                  onClick={
                    connectionStatus ? handleConnectionStatusClick : undefined
                  }
                  value={connectionStatus ? connectionStatus : "connected"}
                  label={t("location_details.connection_status")}
                />
                <Divider />
                <DataListItem value={""} label={""} />
              </Grid>
            </Grid>
          </List>
        </Paper>
      </Container>

      {/* TODO implement toggle for the reservation functionality
        <ReserveDoors
          locationID={locationDataResult.value.locationID}
          tenantKey={routeParams.tenantKey}
          onSubmit={() => {setTimeout(updateRentals, WAIT_BETWEEN_DOOR_OPEN_AND_REFETCH_MS);}}
        />
      */}

      <Container sx={{ marginTop: 4 }}>
        <Box>
          <Box style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Typography variant="h4"> {t("common.doors")} </Typography>
            {selectedDoorCardIds.length === 0 ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  doorsResult.type === "RESOLVED"
                    ? setSelectedDoorCardIds(
                      doorsAfterFilter().map((door) => door.doorID),
                      )
                    : null
                }
                disabled={openDoorsRequestResult.type === "PENDING"}
                style={{ margin: "auto 0" }}
              >
                {t("location_details.select_all_button", {
                  number: selectedDoorCardIds.length,
                })}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setSelectedDoorCardIds([])}
                disabled={openDoorsRequestResult.type === "PENDING"}
                style={{ margin: "auto 0" }}
              >
                {t("location_details.deselect_all_button", {
                  number: selectedDoorCardIds.length,
                })}
              </Button>
            )}

            <Box display="flex" alignItems="center" sx={{ml: 'auto'}}>
              <FormControlLabel sx={{mx: '5px'}}
                control={
                  <Switch
                    checked={doorsViewList}
                    onChange={handleDoorsViewType}
                    color="primary"
                  />
                }
                label={t("common.list_view")}
                labelPlacement="end"
              />
            </Box>
          </Box>


          {selectedDoorCardIds.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOpenSelectedDoors}
              disabled={openDoorsRequestResult.type === "PENDING"}
            >
              {t("location_details.open_doors_button", {
                number: selectedDoorCardIds.length,
              })}
            </Button>
          )}
          <IconButton
            aria-label={t("common.refresh")}
            onClick={async () => {
              updateDoors();
              updateRentals();
            }}
            size="large"
          >
            <RefreshRounded />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          {doorsResult.type === "PENDING" && (
            <CircularProgress sx={{ marginLeft: 2 }} />
          )}
          {doorsResult.type === "RESOLVED" &&
            doorsResult.value.length === 0 && (
              <EmptyListText>
                {t("location_details.no_doors_in_list")}
              </EmptyListText>
            )}
          {doorsResult.type === "RESOLVED" &&
            doorsAfterFilter()?.map((door) => {
              const nextReservation = getNextDoorReservations(
                rentalsByDoorId[door.doorID] ?? [],
              );
              const nextOrder = nextReservation
                ? nextReservationsOrders[nextReservation.rental.orderID]
                : null;

              return ( doorsViewList
                ? (
                <DoorList
                  key={door.doorID}
                  door={door}
                  doorResourceTypes={door.resourceTypes}
                  nextReservation={nextReservation}
                  nextOrder={nextOrder}
                  tenantKey={routeParams.tenantKey}
                  locationID={routeParams.locationID}
                  resourceTypes={resourceTypesResponse}
                  selected={selectedDoorCardIds.includes(door.doorID)}
                  handleCardClick={handleClickDoor}
                  handleLockClick={handleClickDoorOpen}
                  isAllowedToOpenDoor={
                    isAllowedToOpenDoor(door.doorID) ?? false
                  }
                />
                ) : (
                  <DoorCard
                    key={door.doorID}
                    door={door}
                    doorResourceTypes={door.resourceTypes}
                    nextReservation={nextReservation}
                    nextOrder={nextOrder}
                    tenantKey={routeParams.tenantKey}
                    locationID={routeParams.locationID}
                    resourceTypes={resourceTypesResponse}
                    selected={selectedDoorCardIds.includes(door.doorID)}
                    handleCardClick={handleClickDoor}
                    handleLockClick={handleClickDoorOpen}
                    isAllowedToOpenDoor={
                      isAllowedToOpenDoor(door.doorID) ?? false
                    }
                  />
                )
              );
            })}
          {doorsResult.type === "REJECTED" && (
            <RequestErrorToast requestError={doorsResult.error} />
          )}
        </Box>
      </Container>

      <Dialog open={confirmOpenPromptDoorIds.length > 0}>
        <DialogTitle>
          {confirmOpenPromptDoorIds.length}{" "}
          {t("location_details.confirm_open_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans
              i18nKey="location_details.confirm_open_text"
              values={{
                number: confirmOpenPromptDoorIds.length,
                location: locationDataResult.value.name,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpenPromptConfirmClick}>
            {" "}
            {t("location_details.confirm")}{" "}
          </Button>
          <Button
            onClick={handleOpenPromptCancelClick}
            color="primary"
            autoFocus
          >
            {t("common.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      {openDoorsRequestResult.type === "REJECTED" ? (
        <RequestErrorToast requestError={openDoorsRequestResult.error} />
      ) : openDoorsRequestResult.type === "RESOLVED" ? (
        <ToastMessage message={t("message.doors_opened")} severity="success" />
      ) : null}
    </Box>
  );
}

export const tenantLocationDetailsRoute: TRoute<
  TLocation,
  { tenantKey: string; locationID: string }
> = {
  path: "/tenants/:tenantKey/locations/:locationID",
  backPath: "/locations",
  Title: LocationDetailsTitle,
  Content: LocationDetailsContent,
  loadData: loadLocationDetails,
};

export const locationDetailsRoute: TRoute<null, { locationID: string }> = {
  path: "/locations/:locationID",
  redirect: ({ locationID }, selectedTenant) =>
    selectedTenant
      ? {
          to: `/tenants/${selectedTenant}/locations/${locationID}`,
          replace: true,
        }
      : null,
};

export type DoorReservation = {
  createdAt: number;
  expiredAt: number;
  rental: TOrder$TikiRental;
  rentalItem: TOrder$TikiRental$RentalItem;
  resource: TOrder$TikiRental$RentalItem["resources"][number];
};
type RentalMapByDoors = Record<string, undefined | DoorReservation[]>;

const getNextDoorReservations = (
  reservation: DoorReservation[],
): DoorReservation | null => {
  const now = Date.now();
  const nextReservations = reservation
    .filter(({ createdAt, expiredAt }) => {
      return now <= expiredAt;
    })
    .sort(
      (reservation1, reservation2) =>
        reservation2.createdAt - reservation1.createdAt,
    );

  return nextReservations[0] ?? null;
};
