import { createContext, useContext } from "react";
import { TAccessToken } from "./auth.js";
import { DateTime } from "luxon";

export type TLocation = {
  thingsID: string;
  timezone: string;
  locationID: string;
  name: string;
  connectionEvents?: {
    disconnectReason?: string;
    eventType: string;
    timestamp: number;
  }[];
};

export type TLocationDoorOpenState = "open" | "closed";
export type TLocationDoorsResponse = {
  [doorID: string]: { status: TLocationDoorOpenState; resourceTypes: string[] };
};
export type TLocationDoor = {
  doorID: string;
  openState: TLocationDoorOpenState;
  resourceTypes: string[];
};
export type TOrder$TikiOrder$Item = {
  itemID: string;
  variation: string;
  option: string;
  eventStartDateTime: string;
  eventEndDateTime: string;
  quantity: number;
};

export type TOrder$TikiOrder = {
  source: string;
  orderID: string;
  items: TOrder$TikiOrder$Item[];
  location: TLocation;
};

export type TOrder$TikiRental$RentalItem = {
  itemID: string;
  resources: {
    containerID: string;
    doorID: string;
    resourceType: string;
  }[];
};

export type TOrder$TikiRental = {
  rentalID: string;
  orderID: string;
  locationID: string;
  rentalItems: TOrder$TikiRental$RentalItem[];
  expiredAt: string;
  createdAt: string;
};

export type TOrder$RegiondoOrder$Resource = {
  resource_id: string;
  consumption: string;
  resource_name: string;
};

export type TOrder$RegiondoOrder$TicketCode = {
  reference_id: string;
  validity_status: string;
  code: string;
  barcode_type: string;
  type: string;
  expiry_date: string;
  expiry_time: string;
  generated_at: string;
  ticket_pdf: string;
  gift_redeem_item: [];
};

export type TOrder$RegiondoOrder$Item = {
  unique_item_id: string;
  booking_key: string;
  product_id: string;
  ticket_name: string;
  ticket_variation: string;
  ticket_option: string;
  ticket_option_id: number;
  ticket_qty: number;
  ticket_qty_canceled: number;
  ticket_codes: TOrder$RegiondoOrder$TicketCode[];
  resources: TOrder$RegiondoOrder$Resource[];
  status: string;
  event_date_time: string;
  row_total_incl_tax: number;
  row_total_excl_tax: number;
  row_total_tax_amount: number;
  price_per_one_incl_tax: number;
  price_per_one_excl_tax: number;
  currency: string;
  payment_status: string;
  item_type_code: string;
  sales_channel: string;
};

export type TOrder$RegiondoOrder$DiscountInfo = {
  code: string;
  amount: string;
  is_valid: number;
};

export type TOrder$RegiondoOrder = {
  info_generated_at: string;
  order_number: string;
  order_id: string;
  purchased_at: string;
  timezone: string;
  items: TOrder$RegiondoOrder$Item[];
  sales_channel: string;
  payment_method: string;
  discount_info: TOrder$RegiondoOrder$DiscountInfo[];
  total_tickets_ordered: number;
  total_tickets_created: number;
  payment_additional_info: string;
  contact_data: {
    firstname: string;
    lastname: string;
    email?: string | null | undefined;
    telephone?: string | null | undefined;
  };
  subtotal: number;
  tax_amount: number;
  grand_total: number;
  currency: string;
  payment_status: {
    code: string;
    label: string;
    offline_amounts: [];
  };
};

export type TOrder = {
  tikiOrder: TOrder$TikiOrder;
  tikiRental: TOrder$TikiRental[];
  regiondoOrder: TOrder$RegiondoOrder;
  order?: { raw: { contact_data: { firstname: string; lastname: string } } };
};

type TLegacyGetResourceTypesResponse = string[];

export type TGetResourceTypesResponse = {
  tenantResourceTypes: string[];
  allowCustom: boolean;
};
export type TLocationDoorsPUTRequestBody = Array<{ id: string; state: "open" }>;

export async function getLocations(
  { fetch }: TAPI,
  tenantKey: string,
): Promise<TLocation[]> {
  const response = await fetch(`${tenantKey}/locations`, {
    method: "GET",
  });

  return response.json();
}

export async function getLocation(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
): Promise<TLocation> {
  const response = await fetch(`${tenantKey}/locations`, {
    method: "GET",
  });

  const foundLocation = (await response.json()).find(
    (current: TLocation) => locationID === current.locationID,
  );
  if (!foundLocation) {
    throw new Error(
      `Received locations but location ${locationID} was not included.`,
    );
  }
  return foundLocation;
}

const formatDate = (date: DateTime) => date.toFormat("yyyy-MM-dd");

export async function getRentalsForLocation(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
  startDate: DateTime,
  endDate: DateTime,
): Promise<TOrder$TikiRental[]> {
  const response = await fetch(
    `${tenantKey}/locations/${locationID}/rentals?startDate=${formatDate(
      startDate,
    )}&endDate=${formatDate(endDate)}`,
    {
      method: "GET",
    },
  );

  return await response.json();
}

export async function getLocationDoors(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
): Promise<TLocationDoor[]> {
  const response = await fetch(`${tenantKey}/locations/${locationID}/doors`, {
    method: "GET",
  });

  const doorsResponse = (await response.json()) as TLocationDoorsResponse;

  const transformedResponse = [];

  for (const [doorID, value] of Object.entries(doorsResponse)) {
    transformedResponse.push({
      doorID,
      openState: value.status,
      resourceTypes: value.resourceTypes,
    });
  }
  return transformedResponse;
}

export async function openLocationDoor(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
  doorID: string,
): Promise<void> {
  const response = await fetch(
    `${tenantKey}/locations/${locationID}/doors/${doorID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify("open"),
    },
  );

  if (response.status >= 300) {
    throw new RequestError(response);
  }
}

export type LocationRentalPOSTRequestBody = {
  durationMs: number;
  count: number;
};

export async function createAdminRental(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
  requestBody: LocationRentalPOSTRequestBody,
): Promise<void> {
  const response = await fetch(`${tenantKey}/locations/${locationID}/rentals`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody satisfies LocationRentalPOSTRequestBody),
  });

  if (response.status >= 300) {
    throw new RequestError(response);
  }
}
export async function openLocationDoors(
  { fetch }: TAPI,
  tenantKey: string,
  locationID: string,
  doorIDs: string[],
): Promise<void> {
  const response = await fetch(`${tenantKey}/locations/${locationID}/doors`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      doorIDs.map((doorId) => ({
        id: doorId,
        state: "open",
      })) satisfies TLocationDoorsPUTRequestBody,
    ),
  });

  if (response.status >= 300) {
    throw new RequestError(response);
  }
}

export class RequestError extends Error {
  response: Response | null;
  error: Error | null;

  constructor(responseOrError: Response | Error) {
    if (responseOrError instanceof Error) {
      super(`Request failed with error "${responseOrError.message}"`);
      this.response = null;
      this.error = responseOrError;
    } else {
      super(
        `Request failed with ${responseOrError.status} ${responseOrError.statusText}`,
      );
      this.response = responseOrError;
      this.error = null;
    }
  }
}

export async function getOrder(
  { fetch }: TAPI,
  tenantKey: string,
  orderID: string,
): Promise<TOrder> {
  const response = await fetch(`${tenantKey}/orders/${orderID}`, {
    method: "GET",
  });

  return response.json();
}

export async function getResourceTypes(
  { fetch }: TAPI,
  tenantKey: string,
  locationId: string,
): Promise<TGetResourceTypesResponse> {
  const response = await fetch(`${tenantKey}/locations/${locationId}/types`, {
    method: "GET",
  });

  const body = (await response.json()) as
    | TGetResourceTypesResponse
    | TLegacyGetResourceTypesResponse;

  if (Array.isArray(body)) {
    return { allowCustom: false, tenantResourceTypes: body };
  }

  return body;
}

export async function updateResourceTypes(
  { fetch }: TAPI,
  tenantKey: string,
  locationId: string,
  doorId: string,
  resourceTypes: string[],
): Promise<void> {
  const response = await fetch(
    `${tenantKey}/locations/${locationId}/doors/${doorId}/types`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        types: resourceTypes,
      }),
    },
  );

  if (response.status >= 300) {
    throw new RequestError(response);
  }
}

export type TAPI = {
  fetch: (
    endpoint: string,
    options: Parameters<typeof globalThis.fetch>[1],
  ) => ReturnType<typeof globalThis.fetch>;
  getPermissions: () => string[];
};

export function createAPI(accessToken: TAccessToken): TAPI {
  return {
    getPermissions: () => accessToken.decoded.permissions,
    fetch: async (endpoint, options) => {
      try {
        const response = await globalThis.fetch(
          `${process.env.REACT_APP_API_URL}/admin/v1/${endpoint}`,
          {
            ...options,
            headers: {
              ...options?.headers,
              Authorization: `Bearer ${accessToken.token}`,
            },
          },
        );

        if (response.status >= 300) {
          throw new RequestError(response);
        }

        return response;
      } catch (error) {
        throw new RequestError(
          error instanceof Error ? error : new Error(error?.toString()),
        );
      }
    },
  };
}

const apiContext = createContext<TAPI | null>(null);

export const APIProvider = apiContext.Provider;

export function useAPI(): TAPI {
  const api = useContext(apiContext);

  if (!api) {
    throw new Error("Expected api from provider");
  }

  return api;
}
