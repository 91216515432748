import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Autocomplete,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { MoreHoriz } from "@mui/icons-material";
import { ChangeEvent, useState, SyntheticEvent, useMemo } from "react";
import {
  TGetResourceTypesResponse,
  updateResourceTypes,
  useAPI,
} from "../api.js";


const DialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

type Props = {
  resourceTypes: TGetResourceTypesResponse;
  doorResourceTypes: string[];
  tenantKey: string;
  locationID: string;
  doorID: string;
};

type ResourceType = string;

export default function ResourceTypeDialog({
  resourceTypes,
  doorResourceTypes,
  tenantKey,
  locationID,
  doorID,
}: Props) {

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const api = useAPI();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if(resourceTypes.allowCustom || !isCustomResourceTypeAdded) {

      await updateResourceTypes(
        api,
        tenantKey,
        locationID,
        doorID,
        selectedResourceTypes
      );
      setOpen(false);
      window.location.reload();
    }
  };

  const [selectedResourceTypes, setSelectedResourceTypes] = useState<ResourceType[]>(doorResourceTypes);
  const isCustomResourceTypeAdded = selectedResourceTypes.filter((rt) => !resourceTypes.tenantResourceTypes.includes(rt)).length > 0

  const handleResourceTypeChange = (event: ChangeEvent<{}>, newResourceTypes: ResourceType[]) => {
    setSelectedResourceTypes(newResourceTypes);
  };

  const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <>
      <DialogButton onClick={handleClickOpen}>
        <MoreHoriz />
      </DialogButton>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <Box>
          <DialogTitle>{t("location_details.resource_type")}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 300,
                width: "50vh",
                rowGap: (t) => t.spacing(2),
              }}
              alignItems="stretch"
            >
              <FormControl>
                <Autocomplete
                  multiple
                  freeSolo={ resourceTypes.allowCustom }
                  autoHighlight
                  sx={{pt: '10px'}}
                  value={selectedResourceTypes}
                  onChange={handleResourceTypeChange}
                  options={resourceTypes.tenantResourceTypes}
                  noOptionsText={<Typography sx={{color: 'red'}}>{t("location_details.custom_resource_type_error")}</Typography>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={resourceTypes.allowCustom ? t("location_details.select_or_create_resource_type") : t("location_details.select_resource_type")}
                      placeholder={t("location_details.resource_type_placeholder")}
                    />
                  )}
                  renderTags={(value: ResourceType[], getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });

                      return(
                        <Chip
                          key={key}
                          label={option}
                          {...tagProps}
                          style={{ margin: '2px' }}
                        />
                      )
                    })
                  }
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={handleClose}>
              {t("location_details.cancel")}
            </DialogButton>
            <DialogButton onClick={handleSubmit}>
              {t("location_details.ok")}
            </DialogButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
