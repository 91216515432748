import { Box, Select, InputLabel, TextField, Autocomplete, MenuItem, Checkbox, FormControl, List, ListItem, ListItemButton, ListItemText, ListSubheader, SelectChangeEvent } from '@mui/material';
import { ChangeEventHandler, ChangeEvent, useCallback, useState, useContext } from "react";
import { DoorsFilterContext } from '../App.js';
import {
  Construction,
  ErrorOutline
} from "@mui/icons-material";


export const DoorsFilter = () => {

  const {statusFilter, resourceTypeFilter, resourceTypeValues} = useContext(DoorsFilterContext)

  const [dropdownFilter, setDropdownFilter] = resourceTypeFilter
  const [inputResourceTypeValue, setInputResourceTypeValue] = useState<string>('');
  const [checkboxFilter, setCheckboxFilter] = statusFilter
  const [resourceTypeOptions, setResourceTypeOptions] = resourceTypeValues

  // Checkbox options with labels and icons
  const checkboxOptions = [
    { label: 'Maintenance', value: 'maintenance', icon: <Construction sx={{mr: '5px'}} /> },
    { label: 'Unoccupied', value: 'unoccupied', icon: <ErrorOutline sx={{transform: "rotate(180deg)", color: 'green', mr: '5px'}} /> },
    { label: 'Occupied', value: 'occupied', icon: <ErrorOutline sx={{color: 'red', mr: '5px'}} /> }
    // { label: 'Notice', value: 'notice', icon: <ChatBubbleOutline sx={{ mr: '5px' }} /> },
  ];

  const handleDropdownChange = (event: any) => {
    if (event.target.textContent !== '') {
      setDropdownFilter(event.target.textContent);
    } else {
      setDropdownFilter(null)
    }
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkValue = event.target.checked;

    if (checkValue) {
      setCheckboxFilter(event.target.name);
    } else {
      setCheckboxFilter('');
    }
  }

  return (
    <Box>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        subheader={<ListSubheader sx={{fontWeight: 'bold', fontSize: 18}}>{ 'Filter' }</ListSubheader>}
      >
        <ListItem>
          <FormControl fullWidth size={'small'} color={'primary'}>

            <Autocomplete
              value={dropdownFilter}
              size={'small'}
              autoHighlight
              onChange={handleDropdownChange}
              inputValue={inputResourceTypeValue}
              onInputChange={(event, newInputValue) => {
                setInputResourceTypeValue(newInputValue);
              }}
              id="resourceTypeSelector"
              options={resourceTypeOptions }
              sx={{borderRadius: '20px'}}
              renderInput={
                (params) => 
                  <TextField {...params}
                    label="Resource Type"
                    sx={{
                      borderRadius: '30px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '30px',
                        py: '0px'
                      },
                    }}
                  />
              }
            />
          </FormControl>
        </ListItem>

        {checkboxOptions.map((option) => {
          const labelId = `filter-${option.value}`;

          return (
            <ListItem
              key={option.value}
              disablePadding
            >
              <ListItemButton dense>
                <Checkbox
                  edge="start"
                  checked={checkboxFilter === option.value}
                  tabIndex={-1}
                  inputProps={{ 'aria-labelledby': labelId }}
                  name={option.value}
                  sx={{py: '0px'}}
                  onChange={ handleCheckboxChange }
                />

                {option.icon}
                <ListItemText id={labelId} primary={option.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

